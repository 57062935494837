<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 20:12:56
 * @LastEditTime: 2021-05-13 11:09:21
 * @LastEditors: Please set LastEditors
 * @Description: 账号信息
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\satffDetail\components\information.vue
-->
<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      label-width="200px"
      :rules="rules"
      :inline="true"
      size="mini"
      :label-position="labelPosition"
    >
      <el-form-item label="姓名" prop="user_name">
        <el-input
          v-model="form.user_name"
          placeholder="请输入姓名"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="form.mobile"
          placeholder="请输入手机号"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          filterable
          placeholder="请选择部门"
          autocomplete="off"
          style="width: 178px"
        >
          <el-option
            v-for="(list, index) in baseAdminsd"
            :key="index"
            :label="list.text"
            :value="list.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职能" prop="user_type">
        <el-select
          v-model="form.user_type"
          multiple
          filterable
          placeholder="请选择职位"
          style="width: 178px"
        >
          <el-option label="销售" value="1"></el-option>
          <el-option label="送货" value="2"></el-option>
          <el-option label="内部管理" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办公电话" prop="office_mobile">
        <el-input
          v-model="form.office_mobile"
          placeholder="请输入办公电话"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="销售区域" prop="areas">
        <el-select
          v-model="form.areas"
          multiple
          filterable
          placeholder="请选择销售区域"
          style="width: 178px"
        >
          <el-option
            v-for="(list, inde) in areasd"
            :key="inde"
            :label="list.area_name"
            :value="String(list.id)"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-row>
        <el-divider></el-divider>
      </el-row>
      <el-form-item label="开通账号" prop="account_status">
        <el-row style="display: flex; flex-direction: row">
          <el-select
            v-model="form.account_status"
            filterable
            placeholder="请选择账号"
            style="width: 178px"
          >
            <el-option label="正式账号" value="2"></el-option>
            <el-option label="暂不开通" value="0"></el-option>
            <el-option label="试用账号" value="1"></el-option>
          </el-select>
          <el-popover placement="top" width="400" trigger="click">
            <p>开通正式账号：占用您的端口数量，使用期限为协议到期时间</p>
            <p>
              开通试用账号：临时试用体验账号，使用期限为7天，您可创建2个试用账号
            </p>
            <p>
              暂不开通账号：员工没有任何电脑后台和手机端系统使用权限，仅留存员工信息，可体现在单据中
            </p>
            <div slot="reference">
              <i
                class="el-icon-warning color"
                style="font-size: 25px; color: rgb(240, 175, 90)"
              ></i>
            </div>
          </el-popover>
        </el-row>
      </el-form-item>
      <el-row v-if="form.account_status != '0' && form.account_status != ''">
        <el-form-item label="角色" prop="roles">
          <el-select
            v-model="form.roles"
            multiple
            filterable
            placeholder="请选择角色"
            style="width: 178px"
          >
            <el-option
              v-for="(item, listindex) in rolelist"
              :key="listindex"
              :label="item.role_name"
              :value="String(item.role_id)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录账号" prop="mobile">
          <el-input
            v-model="form.mobile"
            disabled
            placeholder="请输入登录账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input
            v-model="form.pwd"
            type="password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="允许改销售价" prop="is_edit_price">
          <el-row style="display: flex; flex-direction: row">
            <el-switch
              v-model="form.is_edit_price"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <el-popover placement="top" width="400" trigger="click">
              <p>允许改销售价:默认为开，关闭后该员工不可修改订单销售价</p>

              <div slot="reference">
                <i
                  class="el-icon-warning color"
                  style="font-size: 25px; color: rgb(240, 175, 90)"
                ></i>
              </div>
            </el-popover>
          </el-row>
        </el-form-item>
        <el-form-item label="允许改调拨价" prop="is_edit_price">
          <el-row style="display: flex; flex-direction: row">
            <el-switch
              v-model="form.is_edit_price_allot"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <el-popover placement="top" width="400" trigger="click">
              <p>允许改调拨价:默认为关，开启后该员工可修改调拨单价格</p>

              <div slot="reference">
                <i
                  class="el-icon-warning color"
                  style="font-size: 25px; color: rgb(240, 175, 90)"
                ></i>
              </div>
            </el-popover>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="状态" prop="is_close">
          <el-radio-group v-model="form.is_close">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-row>

      <div class="purple">个人信息</div>
      <el-row>
        <el-divider></el-divider>
      </el-row>
      <el-form-item label="性别" prop="sex">
        <el-select
          v-model="form.sex"
          filterable
          placeholder="请选择性别"
          style="width: 178px"
        >
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职务" prop="user_job">
        <el-input v-model="form.user_job"></el-input>
      </el-form-item>
      <el-form-item label="住址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idcard">
        <el-input v-model="form.idcard"></el-input>
      </el-form-item>
      <el-form-item label="亲友姓名" prop="friends_name">
        <el-input v-model="form.friends_name"></el-input>
      </el-form-item>
      <el-form-item label="亲友电话" prop="friends_mobiel">
        <el-input v-model="form.friends_mobiel"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import md5 from 'md5'
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        console.log(rule, '手机号验证导致的吗')
        const phoneReg = /^1[2|3|4|5|6|7|8|9][0-9]{9}$/
        if (!value) {
          return callback(new Error('电话号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('电话号码格式不正确'))
            }
          }
        }, 100)
      }

      return {
        areasd: '', //quyu
        baseAdminsd: '',
        rolelist: '',
        labelPosition: 'right',
        form: {
          user_name: '',
          office_mobile: '',
          mobile: '',
          depart_id: '',
          user_type: '',
          areas: '',
          roles: '',
          is_edit_price: 1,
          is_edit_price_allot: 0,
          is_close: '0',
          account_status: '',
          login_user: '',
          pwd: '',
          //个人信息
          user_job: '',
          sex: '',
          address: '',
          idcard: '',
          friends_name: '',
          friends_mobiel: '',
        },
        rules: {
          user_name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
          depart_id: [
            { required: true, message: '请选择部门', trigger: 'change' },
          ],
          user_type: [
            { required: true, message: '请选择职能', trigger: ['blur'] },
          ],
        },
        url: {
          Newuser: '/baseAdmin/user/add',
          area: '/baseAdmin/dealer-area/index',
          baseAdmin: '/baseAdmin/depart/index',
          role: '/baseAdmin/common/role-list',
        },
      }
    },
    watch: {
      'form.mobile'(val) {
        this.form.login_user = val
      },
    },
    mounted() {
      //区域列表
      this.handlerquytu()
      this.handlerbumen()
      this.handlerjuse()
    },
    methods: {
      //区域列表
      handlerquytu() {
        var data = {
          pageNo: 1,
          pageSize: 1000,
        }
        postAction(this.url.area, data)
          .then((res) => {
            console.log(res + '区域')
            this.areasd = res.data
          })
          .catch((err) => {})
      },
      //部门列表
      handlerbumen() {
        postAction(this.url.baseAdmin, { is_close: 0 })
          .then((res) => {
            console.log(res + 'bumen')
            this.baseAdminsd = res.data.list
          })
          .catch((err) => {})
      },
      //角色
      handlerjuse() {
        postAction(this.url.role, {})
          .then((res) => {
            console.log(res, 'chenggong')
            this.rolelist = res.data
          })
          .catch((err) => {})
      },
      submitForm(show) {
        console.log(show, 'kankan111')
        var data = JSON.parse(JSON.stringify(this.form))

        if (this.form.pwd == undefined || this.form.pwd == '') {
          // try {
          //   var password = md5(data.pwd)
          // } catch (error) {}
          let pass = ''
          console.log(this.form.pwd, '密码为空')
          pass = this.form.pwd
          this.form.pwd = pass
        } else {
          var password = md5(data.pwd)
          data.pwd = password
        }
        console.log(password, 'md5加密')
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (show == '1') {
              if (data.areas.length > 0) {
                data.areas = JSON.stringify(data.areas)
              }
              if (data.roles.length > 0) {
                data.roles = JSON.stringify(data.roles)
              }
              if (data.user_type) {
                data.user_type = JSON.stringify(data.user_type)
              }
              console.log(data)
              postAction(this.url.Newuser, data).then((res) => {
                if (res.code == 200) {
                  this.$message.success(res.msg)
                  this.$emit('refresh')
                  this.resetForm()
                } else {
                  return false
                }
              })
              //该方法解决禅道bug4790号
              // this.resetForm()
            } else {
              if (data.areas.length > 0) {
                data.areas = JSON.stringify(data.areas)
              }
              if (data.roles.length > 0) {
                data.roles = JSON.stringify(data.roles)
              }
              if (this.form.user_type) {
                data.user_type = JSON.stringify(data.user_type)
              }
              console.log(data)
              postAction(this.url.Newuser, data).then((res) => {
                this.$message.success(res.msg)
                if (res.code == 200) {
                  this.resetForm()
                  this.$emit('refresh')
                  this.$emit('closeShow')
                  this.$emit('show', false)
                } else {
                  return false
                }
              })
            }
          } else {
            this.$message({
              message: '请完善必填项后提交',
              type: 'warning',
            })
            // this.resetForm()
            return false
          }
        })
      },
      resetForm() {
        this.$refs['form'].resetFields()
      },
    },
  }
</script>

<style>
  .purple {
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  .el-input--mini {
    //width: 178px !important;
  }
  /* .mgin {
    margin-left: 135px;
  } */
</style>
